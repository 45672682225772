import { openProgram } from "./actions";
import {
  Program,
  OpenProgramState,
  OpenLinkState,
  ActionState,
  Shortcut,
  ActionItem,
  ExpandableItem,
} from "./domain";
import { getShortcut, getPathForAsset } from "./assets";
import { _noop } from "./utils";

export const mkOpenPrgoram = (program: Program): OpenProgramState => ({
  type: "program",
  value: {
    program,
  },
});

export const mkOpenLink = (link: string): OpenLinkState => ({
  type: "link",
  value: {
    link,
  },
});

export const runActionState = (s: ActionState, dispatch: any) => {
  if (s === null) return;
  if (s.type === "program") return dispatch(openProgram(s.value.program));
  if (s.type === "link") return window.open(s.value.link);
  return null;
};

const text = `Hey!
If you see it, I hope your day will be a bit better.
The project is still under construction.

You can find my CV in the start menu.


ENJOY
--- Tomasz Kalinowski
`;

export const shortcuts: Shortcut[] = [
  {
    id: "notepad-readme",
    name: "README.txt",
    filename: getShortcut("notepad"),
    action: mkOpenPrgoram({
      id: "notepad",
      meta: {
        bottomNav: true,
      },
      value: {
        text,
      },
    }),
  },
  {
    id: "github",
    name: "Github.html",
    filename: getShortcut("github"),
    action: mkOpenLink("https://github.com/kalinowsky"),
  },
  {
    id: "linkedin",
    name: "Linkedin.html",
    filename: getShortcut("linkedin"),
    action: mkOpenLink(
      "https://linkedin.com/in/tomasz-kalinowski-software-engineer"
    ),
  },
];

export const navigationItems: Array<ActionItem | ExpandableItem> = [
  {
    type: "expandable",
    value: {
      name: "Games",
      children: [
        {
          type: "expandable",
          value: {
            name: "Not Implemented",
            children: [
              {
                type: "action",
                value: {
                  name: "Minesweeper",
                  action: null,
                },
              },
            ],
          },
        },
      ],
    },
  },
  {
    type: "expandable",
    value: {
      name: "Programs",
      children: [
        {
          type: "expandable",
          value: {
            name: "Not implemented",
            children: [
              {
                type: "action",
                value: {
                  name: "Paint",
                  action: null,
                },
              },
            ],
          },
        },
        {
          type: "action",
          value: {
            name: "NotePad",
            action: mkOpenPrgoram({
              id: "notepad",
              meta: { bottomNav: true },
              value: { text: "" },
            }),
          },
        },
      ],
    },
  },
  {
    type: "expandable",
    value: {
      name: "Curriculum Vitae",
      children: [
        {
          type: "action",
          value: {
            name: "CV - Tomasz Kalinowski",
            action: mkOpenLink(getPathForAsset("cv_tomasz_kalinowski.pdf")),
          },
        },
      ],
    },
  },
];
